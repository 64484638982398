<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">
                    Your Holiday Checklist
                </h1>
            </div>
            <div class="box b-shdw-3 mt-5">
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
                <div>

                    <p class="paragraph-text">
                        Holidays should be the most relaxing time of the year, but packing before you jet off can sometimes feel like a chore! To help make sure you don’t forget any of the essentials, we’ve put together a holiday checklist.
                    </p>
                    <p class="paragraph-text">
                        Whether you’re a super organised traveller, getting everything together weeks in advance or a little more last minute, rushing around hours before you fly, don’t panic. Just follow our step by step check list and we’re sure you’ll jet off with everything you need.
                    </p>
                    <p class="paragraph-text">
                        Before you start packing make sure that you have:
                        <ul class="main-list-class">
                            <li class="list-item"><a href="get-quote" target="_blank">Arranged Travel Insurance</a> (we can help with that!)</li>
                            <li class="list-item">Valid Passport(s)</li>
                            <li class="list-item">Had any necessary vaccinations (check with your GP if you’re unsure)</li>
                        </ul>
                    </p>    
                    <!-- STEP ONE TEXT -->
                    <p class="blue-text">Step One: Documents</p>
                    <p class="paragraph-text">
                        Before you start packing make sure you have the essential documents you need to travel. Forgotten shampoo or a misplaced pair of shorts can easily be replaced at the airport, unlike your official travel documents.
                    </p>
                    <p class="paragraph-text">
                        Consider investing in a travel wallet to keep everything in order, or where possible go paperless and have everything stored on your phone. However, be sure to have your phone fully charged, with a charger on hand so you can access your documents at all times.
                    </p>
                    <p class="paragraph-text">
                        Here is a list of documents that you should have with you while travelling:
                        <ul class="main-list-class">
                            <li class="list-item">Valid passport(s) - travel experts now advise you also email a copy to yourself</li>
                            <li class="list-item">Travel insurance documents</li>
                            <li class="list-item">Travel Visa (if relevant)</li>
                            <li class="list-item">Driving licence/Car hire information (if relevant)</li>
                            <li class="list-item">EHIC (European Health Insurance Card) or GHIC (Global Health Insurance Card), if travelling within Europe</li>
                            <li class="list-item">Airport parking details (if relevant)</li>
                            <li class="list-item">Flight tickets and itinerary</li>
                            <li class="list-item">Transfer information (if relevant)</li>
                            <li class="list-item">Accommodation details</li>
                            <li class="list-item">Your ATOL certificate (if you have one)</li>
                            <li class="list-item">Medical information (such as medical card/bracelet)</li>
                        </ul>
                    </p>
                    <!-- STEP TWO TEXT -->
                    <p class="blue-text">Step Two: Hand Luggage</p>
                    <p class="paragraph-text">
                        It’s tempting to cram any overflow items into our hand luggage during the last minute packing rush! However, make sure to check your flight booking information in advance for the size and weight you’re allowed to take on board. 
                    </p>
                    <p class="paragraph-text">
                        If you’re travelling with children, think about how many hand luggage items you could end up carrying yourself when debating which bag to choose!
                    </p>
                    <p class="paragraph-text">
                        Here’s a little hand luggage packing list to help you remember the essentials:
                    </p>
                    <ul class="main-list-class">
                        <li class="list-item">Wallet/purse</li>
                        <li class="list-item">Currency</li>
                        <li class="list-item">Keys</li>
                        <li class="list-item">Mobile phone</li>
                        <li class="list-item">Mobile phone charger</li>
                        <li class="list-item">Laptop/tablet (and charger)</li>
                        <li class="list-item">Headphones</li>
                        <li class="list-item">Essential medication</li>
                        <li class="list-item">Travel adaptor</li>
                        <li class="list-item">Sunglasses</li>
                        <li class="list-item">Glasses/contact lenses</li>
                        <li class="list-item">Hearing aids</li>
                        <li class="list-item">Camera (and battery charger)</li>
                        <li class="list-item">Warm layers (jumper or blanket)</li>
                        <li class="list-item">Travel pillow</li>
                        <li class="list-item">Jewellery</li>
                        <li class="list-item">Book/magazine/handheld game console</li>
                        <li class="list-item">Pen</li>
                        <li class="list-item">Snacks</li>
                        <li class="list-item">Reusable Water Bottle (to be filled before boarding)</li>
                        <li class="list-item">Hand sanitiser (must be under 100ml)</li>
                        <li class="list-item">Sanitary products</li>
                        <li class="list-item">Toothbrush and toothpaste</li>
                        <li class="list-item">Eye mask</li>
                        <li class="list-item">Earplugs</li>
                    </ul>
                    <p class="paragraph-text">
                        If you’re travelling with children, there are a few more things to add to the list:
                    </p>  
                    <ul class="main-list-class">
                        <li class="list-item">Entertainment (tablet/handheld games console/toys/games/books)</li>
                        <li class="list-item">Tablet/games console charger</li>
                        <li class="list-item">Snacks/baby food</li>
                        <li class="list-item">Formula</li>
                        <li class="list-item">Bottles</li>
                        <li class="list-item">Change of clothes</li>
                        <li class="list-item">Medication (if relevant)</li>
                        <li class="list-item">Dummy (if relevant)</li>
                        <li class="list-item">Nappies</li>
                        <li class="list-item">Baby wipes</li>
                        <li class="list-item">Nappy bags</li>
                        <li class="list-item">Warm layers (jumper or blanket)</li>
                    </ul>  

                    <!-- STEP THREE TEXT -->
                    <p class="blue-text">Step Three: Checked Bags</p>

                    <p class="paragraph-text">
                        Check on your flight information before you start packing, to check exactly what size and weight of suitcase you’re allowed to travel with. If possible, weigh your suitcase before you arrive at the airport. This helps avoid any delays and unexpected charges from luggage over your weight restriction.
                    </p>
                    <p class="paragraph-text">
                        If you’re travelling with children of course you’ll need to pack all their clothing, toiletries and other holiday essentials too. Often children will be allocated their own hand luggage or checked bag allowance on your flight, so be sure to take advantage of all available space. If you’re still struggling, there are a number of services abroad where you can hire essentials for babies and children. Things such as pushchairs, sterilisers and travel cots can be hired for a small fee. However do check with your hotel before booking, as they may have a number of items they can also lend you that might save you money (and space!).
                    </p>
                    <p class="paragraph-text">
                        To help you pack effectively, here is a checked bag checklist!
                    </p>
                    <p class="paragraph-text bold-text">CLOTHING</p>
                    <p class="paragraph-text">
                        What clothing to pack of course depends hugely on the climate of the country you’re visiting, however hopefully this list helps you remember the essentials:
                    </p>
                    <ul class="main-list-class">
                        <li class="list-item">Jacket/coat</li>
                        <li class="list-item">Tops (day/evening)</li>
                        <li class="list-item">Shorts</li>
                        <li class="list-item">Trousers/skirts/dresses</li>
                        <li class="list-item">Pyjamas</li>
                        <li class="list-item">Underwear</li>
                        <li class="list-item">Socks</li>
                        <li class="list-item">Sandals/flip flops</li>
                    </ul>
                    <p class="paragraph-text bold-text">TOILETRIES</p>
                    <ul class="main-list-class">
                        <li class="list-item">Sun cream</li>
                        <li class="list-item">After sun</li>
                        <li class="list-item">Insect repellent products/mosquito net</li>
                        <li class="list-item">Hand cream</li>
                        <li class="list-item">Face wash</li>
                        <li class="list-item">Face cream</li>
                        <li class="list-item">Deodorant</li>
                        <li class="list-item">Body lotion</li>
                        <li class="list-item">Lip balm</li>
                        <li class="list-item">Contact lens solution</li>
                        <li class="list-item">Shower gel</li>
                        <li class="list-item">Shampoo</li>
                        <li class="list-item">Conditioner</li>
                        <li class="list-item">Razor</li>
                        <li class="list-item">Shave gel</li>
                    </ul>    
                    <p class="paragraph-text bold-text">OTHER HOLIDAY ESSENTIALS</p>
                    <ul class="main-list-class">
                        <li class="list-item">Batteries</li>
                        <li class="list-item">First aid kit</li>
                        <li class="list-item">Mini sewing kit</li>
                        <li class="list-item">Contraception</li>
                        <li class="list-item">Hairdryer</li>
                        <li class="list-item">Tissuesv
                        <li class="list-item">Hair brush</li>
                        <li class="list-item">Phrase book</li>
                        <li class="list-item">Guidebook</li>
                        <li class="list-item">Laundry bag (to separate dirty and clean clothes)</li>
                    </ul> 
                    <p class="paragraph-text">
                        Try to pack smart so you have a little space for any souvenirs you decide to bring back with you. Also remember that unless you’re going to a remote location, your destination will likely sell all the holiday essentials. Sometimes choosing to buy a number of items when you get there can help to lighten your load a little.
                    </p> 
                    
                    <!-- STEP FOUR TEXT -->
                    <p class="blue-text">Step Four: Have a great time!</p>
                    <p class="paragraph-text">
                        You’ve worked hard for this holiday, so it’s time to kick back, relax and enjoy yourself.
                    </p>    

                    <!-- STEP FIVE TEXT -->
                    <p class="blue-text">Choosing your Travel Insurance</p>
                    <p class="paragraph-text">
                        We might not be able to advise on which sandals to pack, but we can certainly assist you with travel insurance for your trip! Our online system makes it so easy, not only to find a quote for your holiday, but also to get a clear breakdown of exactly what you’re paying for.
                    </p>
                    <p class="paragraph-text">
                        Having travel insurance in place can really help to give you peace of mind when you’re travelling. Obviously, nobody wants anything to go wrong on holiday, but if it does you know you have the right protection to help.
                    </p>   
                    <p class="paragraph-text">
                        At Covered2go we have over 100 years of experience in the travel insurance industry. We specialise in travel insurance and have “Excellent” rated products and customer service.
                    </p>    
                    <p class="paragraph-text">
                        If you have any questions and want to speak to a member of our team, then please don’t hesitate to get in touch. We can help you get the right level of cover so you can simply enjoy your holiday.
                    </p>    

                    <br />
                    <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />
                    <info-boxes />
                    <!-- <trustpilot-banner /> -->
                </div>
            </div>
        </div>    
    </transition>
</template>

<script>
    import InfoBoxes from '../components/common/InfoBoxes';
    //import TrustpilotBanner from '@/components/common/trustpilot/TrustpilotBanner';
    import InfoButtonOptions from '@/components/common/InfoButtonOptions';
    export default {name: "HolidayChecklist",data() {return {}},methods: {},components: {
        InfoBoxes,
        //TrustpilotBanner,
        InfoButtonOptions,
    },created() {}}
</script>

<style lang="scss" scoped>
    .main-page-title {color: $c2g_orange; font-size:130%; text-decoration:none; text-align:left; padding-bottom:15px; font-weight: bold;}
    .paragraph-text {color:black; font-size:100%; padding-bottom:15px;}
    .main-list-class{padding-left:25px; padding-bottom:15px;}
    .list-item{list-style-type: disc; color:black;}
    .blue-text {margin-top: 10px; text-align:left; color: $c2g_blue; font-weight: bold; font-size:115%; padding-bottom:15px;}
    .bold-text{font-weight:bold;}
</style>